import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Topupoffer } from "../../model/topupoffer.interface";
import { environment } from "../../../environments/environment";
import { CommonService } from "../common.service";

@Injectable({
  providedIn: "root",
})
export class TopupService {
  public operatorMap: string[] = ["", "GP", "BL", "RB", "", "TT", "AB"];
  public offerRecharge = new BehaviorSubject<Topupoffer[]>([]);
  public offerRechargeSelect = new BehaviorSubject<Topupoffer>(null);
  public data: Topupoffer[] = [];
  public offerCall = 0;

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {
    this.commonService.deviceKeyObservable.subscribe((res) => {
      if (res) {
        this.topUpPlansAll();
      }
    });
  }

  favouriteContact(mobile_no) {
    const token = localStorage.getItem("token");
    const device_key = localStorage.getItem("device_key");
    return this.httpClient.post<any>(
      environment.easy_core + "vr/get-favorite-number",
      { mobile_no, token, device_key }
    );
  }

  offerForYou(query) {
    return this.httpClient.get<any>(
      environment.easy_core +
        `robimife/robi-mife-offer-list?customer_msisdn=${query.phone}&operator=${query.operator}`
    );
  }

  processOfferForYou(data) {
    const token = localStorage.getItem("token");
    const device_key = localStorage.getItem("device_key");
    return this.httpClient.post<any>(
      environment.easy_core + "robimife/robi-mife-offer-payment",
      { ...data, token, device_key }
    );
  }

  // short_name?, amount?, is_for_prepaid?, is_for_postpaid?
  topUpPlansAll() {
    const device_key = localStorage.getItem("device_key");
    if (device_key) {
      return this.httpClient
        .get<any>(environment.easy_core + "vr/get-plans")
        .subscribe((res) => {
          if (res && res.data) {
            this.data = res.data
              .map((el) => {
                el.is_selected = false;
                return el;
              })
              .sort((a, b) => {
                return a.amount_min - b.amount_min;
              });
            this.getOfferRechargeListByFilter(null, null, null, null);
          }
        });
    } else {
      if (++this.offerCall < 3) {
        setTimeout(() => this.topUpPlansAll(), 1000);
      }
    }
  }

  getOfferRechargeListByFilter(
    operatorId: number,
    amount: number,
    type: string,
    id: number
  ) {
    let offerData: Topupoffer[] = [];
    let is_select = false;
    offerData = this.data
      .map((el) => {
        el.is_selected = false;
        return el;
      })
      .filter(
        (d1) =>
          !(
            operatorId &&
            this.operatorMap[+operatorId] !== d1.operator_short_name
          )
      )
      .filter((d3) => {
        let tempType = "";
        if (+d3.is_for_postpaid === 1 && type === "postpaid") {
          tempType = "postpaid";
        }
        if (+d3.is_for_prepaid === 1 && type === "prepaid") {
          tempType = "prepaid";
        }
        return !(type && type !== tempType);
      })
      .map((d4) => {
        d4.is_selected = false;
        if (!is_select) {
          d4.is_selected = is_select =
            (id && d4.id === id) ||
            (d4.amount_max === amount &&
              operatorId &&
              (type === "postpaid" || type === "prepaid"));
        }
        return d4;
      });
    this.offerRecharge.next(offerData);
  }

  deleteFavouriteNumber(body) {
    const token = localStorage.getItem("token");
    const device_key = localStorage.getItem("device_key");
    return this.httpClient.post<any>(
      environment.easy_core + "vr/delete-favourite-number",
      {
        ...body,
        token,
        device_key,
      }
    );
  }

  updateFavouriteNumber(body) {
    const token = localStorage.getItem("token");
    const device_key = localStorage.getItem("device_key");
    return this.httpClient.post<any>(
      environment.easy_core + "vr/update-favourite-number",
      {
        ...body,
        token,
        device_key,
      }
    );
  }

  addFavouriteNumber(body) {
    const token = localStorage.getItem("token");
    const device_key = localStorage.getItem("device_key");
    return this.httpClient.post<any>(
      environment.easy_core + "vr/add-favourite-number",
      { ...body, token, device_key }
    );
  }

  getRecentTransaction(body) {
    const token = localStorage.getItem("token");
    const device_key = localStorage.getItem("device_key");
    return this.httpClient.post<any>(
      environment.easy_core + "vr/get-recent-transaction",
      { ...body, token, device_key }
    );
  }

  getRechargeDashboardGraphData(body) {
    const token = localStorage.getItem("token");
    const device_key = localStorage.getItem("device_key");
    return this.httpClient.post<any>(
      environment.easy_core + "vr/get-recharge-dashboard-graph-data",
      {
        ...body,
        token,
        device_key,
      }
    );
  }
}
