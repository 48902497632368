import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, Validators} from "@angular/forms";
import {RechargeRequestModalService} from "./recharge-request-modal-service";
import {isPlatformBrowser} from "@angular/common";
import {CommonService} from "../../../services/common.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-recharge-request-modal',
  templateUrl: './recharge-request-modal.component.html',
  styleUrls: ['./recharge-request-modal.component.scss']
})
export class RechargeRequestModalComponent implements OnInit, OnDestroy {
  contact: any;
  user: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  public mobileRex = /^01[3456789][0-9]{8}$/;
  public emailRex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  constructor(public dialogRef: MatDialogRef<RechargeRequestModalComponent>,
              private rechargeRequestModalService: RechargeRequestModalService,
              private commonService: CommonService,
              public translate: TranslateService,
              private readonly httpClient: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: { recharge: any, is_login: boolean }, private fb: FormBuilder) {
  }

  ngOnInit() {
    if (this.data.is_login) {
      this.user = this.rechargeRequestModalService.getUserData();
    }
    // if (isPlatformBrowser(this.platformId) && this.mobileRex.test(finalMobileOrEmail)) {
    //   localStorage.setItem('user', JSON.stringify({mobile: finalMobileOrEmail}));
    //   this.mobile = finalMobileOrEmail;
    //   this.email = undefined;
    // }
    // if (isPlatformBrowser(this.platformId) && this.emailRex.test(finalMobileOrEmail)) {
    //   localStorage.setItem('user', JSON.stringify({email: finalMobileOrEmail}));
    //   this.email = finalMobileOrEmail;
    //   this.mobile = undefined;
    // }

    this.contact = this.fb.group({
      name: [this.user && this.user.name, [Validators.required]],
      requester_identifier: [(this.user && this.user.mobile) || this.user && this.user.email, [Validators.required]],
      requested_identifier: ['', [Validators.required]],
    });
  }

  onNoClick() {
    this.dialogRef.close();
    // if (this.okayCallbackFunction instanceof Function) {
    //   this.okayCallbackFunction();
    // }
  }

  rechargeRequestSubmit() {
    this.rechargeRequestModalService.rechargeRequest({
      name: this.contact.value.name,
      requester_identifier: this.contact.value.requester_identifier,
      amount: this.data.recharge.amount,
      operator: this.rechargeRequestModalService.getOperator(this.data.recharge.operator).toLocaleLowerCase(),
      operator_id: this.data.recharge.operator,
      msisdn: this.data.recharge.phone,
      connection_type: this.data.recharge.type,
      requested_identifier: this.contact.value.requested_identifier,
    }).pipe(takeUntil(this.onDestroy$)).subscribe(resp => {
      if (resp.status === 'success' || resp.code === 200) {
        this.commonService.successPopup(this.translate.instant('popup.success'), resp.message);
      } else {
        this.commonService.errorPopup(this.translate.instant('popup.fail'), resp.message);
      }
      this.onNoClick();
    });
  }

  getOperator(operator: string) {
    return this.rechargeRequestModalService.getOperator(operator);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
