import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})
export class SuccessPopupComponent implements OnInit {
  public head: any;
  public message: any;
  okayCallbackFunction: Function;

  constructor(public dialogRef: MatDialogRef<SuccessPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {}) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
    if (this.okayCallbackFunction instanceof Function) {
      this.okayCallbackFunction();
    }
  }
}
