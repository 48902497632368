import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from '../../services/common.service';
import {isPlatformBrowser} from "@angular/common";

declare var Swiper: any;

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  announcements: any = [];
  annSwiper: any;

  constructor(private commonService: CommonService, public translate: TranslateService, @Inject(PLATFORM_ID) private platformId: Object) {
    //
  }

  ngOnInit() {
    this.documentReady();
    this.getAllAnnouncements();
  }


  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  getAllAnnouncements() {
    this.commonService.getAnnouncements().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (res.code === 200 || res.status === 'success') {
        this.announcements = res.data;
        this.annSwiper.on('observerUpdate', function () {
          //
        });
      }
    });
  }

  documentReady() {
    if (isPlatformBrowser(this.platformId)) {
      this.annSwiper = new Swiper('#small-offer-carousel-anc', {
        slidesPerView: 2,
        spaceBetween: 20,
        speed: 1500,
        observer: true,
        observeParents: true,
        // observeSlideChildren: true,
        loop: true,

        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.small-offer-carousel-next',
          prevEl: '.small-offer-carousel-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          }
        }
      });
    }
  }

}
