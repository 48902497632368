import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../services/common.service';
import {takeUntil} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import "./footer.component.scss";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  menus: any = [];

  constructor(public translate: TranslateService, private commonService: CommonService, @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit() {
    this.getAllMenus();
    if (isPlatformBrowser(this.platformId) && localStorage) {
      const lang = localStorage.getItem('lang') || 'en';
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.commonService.currentLanguageBehavior.next(lang);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  changeLanguage(lang) {
    if (isPlatformBrowser(this.platformId) && localStorage) {
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.commonService.currentLanguageBehavior.next(lang);
      localStorage.setItem('lang', lang);
    }
  }

  getAllMenus() {
    this.commonService.getMenus().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (res.code === 200 || res.status === 'success') {
        this.menus = res.data;
      }
    });
  }

}
