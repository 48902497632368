import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from './common-components/not-found/not-found.component';
import {MainLayoutComponent} from './common-components/main-layout/main-layout.component';
import {DashboardLayoutComponent} from './common-components/dashboard-layout/dashboard-layout.component';
import {AuthGuard} from './guards/auth.guard';
import {CorporateSpecialPackAmountsComponent} from './common-components/corporate-special-pack-amounts/corporate-special-pack-amounts.component';


// @ts-ignore
const routes: Routes = [
  {
    path: '', redirectTo: 'recharge/top-up', pathMatch: 'full'
  },
  {
    path: '', component: MainLayoutComponent,
    children: [
      {
        path: 'recharge', loadChildren: () =>
          // @ts-ignore
          import('./modules/recharge/recharge.module').then(m => m.RechargeModule)
      },
      {
        path: 'rr', loadChildren: () =>
          // @ts-ignore
          import('./modules/recharge/recharge.module').then(m => m.RechargeModule)
      },
      {
        path: 'dynamic', loadChildren: () =>
          // @ts-ignore
          import('./modules/bill-payment/bill-payment.module').then(m => m.BillPaymentModule)
      },
      {
        path: 'subscribe', loadChildren: () =>
          // @ts-ignore
          import('./modules/subscribe/subscribe.module').then(m => m.SubscribeModule)
      },
      {
        path: 'insurance', loadChildren: () =>
          // @ts-ignore
          import('./modules/insurance/insurance.module').then(m => m.InsuranceModule)
      },
      {
        path: 'visa-passport', loadChildren: () =>
          // @ts-ignore
          import('./modules/visa-and-passport-fees/visa-and-passport-fees.module').then(m => m.VisaAndPassportFeesModule)
      },
      {
        path: 'hotel-booking', loadChildren: () =>
          // @ts-ignore
          import('./modules/hotel-booking/hotel-booking.module').then(m => m.HotelBookingModule)
      },
      {
        path: 'education-fees', loadChildren: () =>
          // @ts-ignore
          import('./modules/education-fees/education-fees.module').then(m => m.EducationFeesModule)
      },
      {
        path: 'tickets', loadChildren: () =>
          // @ts-ignore
          import('./modules/tickets/tickets.module').then(m => m.TicketsModule)
      }
    ]
  },
  {
    path: '', component: DashboardLayoutComponent, canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard', loadChildren: () =>
          // @ts-ignore
          import('./modules/user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '', component: DashboardLayoutComponent, canActivateChild: [AuthGuard],
    children: [
      {
        path: '', loadChildren: () =>
          // @ts-ignore
          import('./modules/static-pages/static-pages.module').then(m => m.StaticPagesModule)
      }
    ]
  },

  {
    path: 'tickets', component: DashboardLayoutComponent,
    children: [
      {
        path: 'movies',
        loadChildren: () =>
          // @ts-ignore
          import('./modules/tickets/movie-tickets/movie-detail-search/movie-detail-search.module').then(m => m.MovieDetailSearchModule)
      }
    ]
  },
  {
    path: 'tickets', component: DashboardLayoutComponent, children: [
      {
        path: 'air', loadChildren: () =>
          // @ts-ignore
          import('./modules/tickets/air-tickets/air-tickets.module').then(m => m.AirTicketsModule)
      }
    ]
  },
  {
    path: 'tickets', component: DashboardLayoutComponent, children: [
      {
        path: 'bus', loadChildren: () =>
          // @ts-ignore
          import('./modules/tickets/bus-tickets/bus-tickets.module').then(m => m.BusTicketsModule)
      }
    ]
  },
  {
    path: 'corporate', children: [
      {
        // @ts-ignore
        path: '', loadChildren: () => {
          window.location.href = 'https://corporate.easy.com.bd/';
          return '/';
        }
      }
    ]
  },
  {
    path: 'en/signin', redirectTo: 'recharge/top-up',
  },
  {
    path: 'bn/signin', redirectTo: 'recharge/top-up',
  },
  {
    path: 'en/home', redirectTo: 'recharge/top-up',
  },
  {
    path: 'bn/home', redirectTo: 'recharge/top-up',
  }, 
  {path:'corporate-special-pack-amounts',component:CorporateSpecialPackAmountsComponent},
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
