import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "../../services/login.service";
import { LoginStatusService } from "../../services/login-status.service";
import { CommonService } from "../../services/common.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { isPlatformBrowser } from "@angular/common";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from "angularx-social-login";
import { UserService } from "../../services/user.service";
import { User } from "../../model/user.model";
import { RechargeRequestModalService } from "../../modules/recharge/recharge-request-modal/recharge-request-modal-service";

declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  public mobileOrEmail;
  public socialMobileOrEmail;
  public mobile: string;
  public email: string;
  public otp;
  public window = "login";
  public mobileRex = /^01[3456789][0-9]{8}$/;
  public emailRex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  public password: string;
  public registerInfo: FormGroup;
  public timer = 120;
  public responseFromServer: any;
  public $fpKey: any;
  public $mKey: any;
  password1: any;
  password2: any;
  registrationState = false;
  private onDestroy$: Subject<void> = new Subject<void>();
  provider = "";
  access_token = "";
  social_login_id = "";
  forgotPasswordForResendOTP = false;
  isSocialLogin = false;
  rechargeRequester: any;

  constructor(
    private loginService: LoginService,
    private loginStatus: LoginStatusService,
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private authService: SocialAuthService,
    private readonly rechargeRequestModalService: RechargeRequestModalService,
    private userService: UserService
  ) {
    // this.loginWindow = false;
    // this.registrationWindow = false;
    // this.otpWindow = false;
    // this.passwordWindow = true;
  }

  ngOnInit() {
    this.rechargeRequestModalService
      .getMobileOrEmailForRechargeRequest()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => (this.mobileOrEmail = res));
    this.rechargeRequestModalService
      .getRequestBody()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        const token = localStorage.getItem("token");
        if (res && !token) {
          this.rechargeRequester = res;
          this.registerInfo = this.fb.group({
            name: [res.name, []],
            email: [res.email, []],
            mobile: [
              res.mobile,
              [Validators.required, Validators.pattern(this.mobileRex)],
            ],
            password: ["", []],
            password_confirmation: ["", []],
          });
          this.window = "registration";
          $("#loginModal").modal("show");
        }
      });
    this.authService.authState
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        if (!this.isSocialLogin) {
          return;
        }
        const requested_identifier = localStorage.getItem(
          "requested_identifier"
        );
        this.isSocialLogin = false;
        if (!user || !user.email) {
          this.commonService.errorPopup(
            this.translate.instant("popup.error") + "!",
            this.translate.instant("popup.no_email_in_your_social_account")
          );
          return;
        }
        this.provider = user.provider;
        this.access_token = user.authToken;
        this.social_login_id = user.id;
        this.commonService
          .httpPost("social-login", {
            access_token: user.authToken,
            provider: user.provider,
          })
          .subscribe((res) => {
            if (
              requested_identifier !== res.data.email &&
              requested_identifier !== res.data.mobile
            ) {
              localStorage.removeItem("requested_identifier");
              localStorage.removeItem("ruid");
            }
            if (res && res.code === 200 && res.ui === "registration") {
              this.window = res.ui;
              let name = "";
              let email = "";
              if (res && res.data && res.data.name) {
                name = res.data.name;
              } else {
                if (user && user.name) {
                  name = user.name;
                }
              }
              if (res && res.data && res.data.email) {
                email = res.data.email;
              } else {
                if (user && user.email) {
                  email = user.email;
                }
              }
              this.registerInfo = this.fb.group({
                name: [name, []],
                email: [email, []],
                mobile: [
                  "",
                  [Validators.required, Validators.pattern(this.mobileRex)],
                ],
                password: [null, []],
                password_confirmation: [null, []],
              });
              this.registerInfo.updateValueAndValidity();
            } else if (res && res.code === 200) {
              this.loginAction(res);
            } else {
              this.commonService.errorPopup("Fail!", res.message);
            }
          });
      });
    if (isPlatformBrowser(this.platformId)) {
      $(".modal").on("shown.bs.modal", function () {
        $(this).find("[autofocus]").focus();
      });
    }
    if (isPlatformBrowser(this.platformId)) {
      $(".modal").find("[autofocus]").focus();
    }

    let token;
    if (
      isPlatformBrowser(this.platformId) &&
      localStorage.getItem("user") &&
      localStorage.getItem("token")
    ) {
      token = localStorage.getItem("token");
    }
    if (token) {
      this.loginService
        .loginStatus(token)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (res) => {
            if (res.status === "success" || res.code === 200) {
              this.loginStatus.loginStatusSet(true);
              this.responseFromServer = undefined;
            } else {
              this.loginStatus.loginStatusSet(false);
              if (isPlatformBrowser(this.platformId) && localStorage) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                this.registrationState = false;
              }
            }
          },
          (err) => {
            this.commonService.errorPopup(
              this.translate.instant("popup.error") + "!",
              this.translate.instant("popup.internal_error")
            );
          }
        );
    }
  }

  ngAfterViewInit() {
    //
  }

  userStatus(social: boolean = false) {
    const finalMobileOrEmail = social
      ? this.socialMobileOrEmail
      : this.mobileOrEmail;
    const requested_identifier = localStorage.getItem("requested_identifier");
    if (finalMobileOrEmail != requested_identifier) {
      localStorage.removeItem("requested_identifier");
      localStorage.removeItem("ruid");
    }
    if (
      isPlatformBrowser(this.platformId) &&
      this.mobileRex.test(finalMobileOrEmail)
    ) {
      localStorage.setItem(
        "user",
        JSON.stringify({ mobile: finalMobileOrEmail })
      );
      this.mobile = finalMobileOrEmail;
      this.email = undefined;
    }
    if (
      isPlatformBrowser(this.platformId) &&
      this.emailRex.test(finalMobileOrEmail)
    ) {
      localStorage.setItem(
        "user",
        JSON.stringify({ email: finalMobileOrEmail })
      );
      this.email = finalMobileOrEmail;
      this.mobile = undefined;
    }
    this.loginService
      .userStatus()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          if (res.status === "success" || res.code === 200) {
            this.responseFromServer = undefined;
            if (res.ui === "registration") {
              this.window = res.ui;
              this.registerInfo = this.fb.group({
                name: ["", Validators.required],
                email: [
                  this.email || "",
                  [Validators.pattern(this.emailRex), Validators.required],
                ],
                mobile: [
                  this.mobile || "",
                  [Validators.required, Validators.pattern(this.mobileRex)],
                ],
                password: ["", [Validators.required, Validators.minLength(6)]],
                password_confirmation: [
                  "",
                  [Validators.required, Validators.minLength(6)],
                ],
              });
            } else if (res.ui === "otp") {
              this.window = res.ui;
              if (isPlatformBrowser(this.platformId) && localStorage) {
                localStorage.setItem("user", JSON.stringify(res.data));
              }
              this.otpTimer();
            } else {
              if (social) {
                this.socialLogin(this.socialMobileOrEmail);
              } else {
                this.window = "password";
              }
            }
          } else {
            if (res.code === 401) {
              this.commonService.logout();
              if (isPlatformBrowser(this.platformId)) {
                $("#loginModal").modal("show");
              }
            } else {
              this.responseFromServer =
                res.message || this.translate.instant("popup.went_wrong");
            }
          }
        },
        (err) => {
          this.responseFromServer = this.translate.instant(
            "popup.internal_error"
          );
        }
      );
  }

  changeNumber() {
    if (this.$mKey) {
      this.window = "mobile";
    } else {
      this.window = "login";
    }
    if (isPlatformBrowser(this.platformId) && localStorage) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    this.forgotPasswordForResendOTP = false;
  }

  login() {
    this.loginService
      .login(this.password)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.loginAction(res);
          if (res && res.code === 200) {
            window.location.reload();
          }
        },
        (err) => {
          this.responseFromServer = this.translate.instant(
            "popup.internal_error"
          );
        }
      );
  }

  loginAction(res, social: boolean = false) {
    if (res.status === "success" || res.code === 200) {
      if (res.ui === "mobile" || res.data.mKey) {
        this.$mKey = res.data.mKey;
        this.$fpKey = undefined;
        this.responseFromServer = undefined;
        this.window = "mobile";
      } else {
        if (isPlatformBrowser(this.platformId) && localStorage) {
          localStorage.setItem("user", JSON.stringify(res.data));
          localStorage.setItem("token", res.token);
        }
        this.userService.$User.next(new User(res.data));
        this.loginStatus.loginStatusSet(true);
        this.responseFromServer = undefined;
        this.window = "login";

        this.email = null;
        this.mobile = null;
        this.registrationState = false;
        this.password = null;
        this.mobileOrEmail = null;

        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            $("#loginModal").modal("hide");
          }
        }, 100);
        window.location.reload();
      }
      // window.location.reload();
    } else {
      this.loginStatus.loginStatusSet(false);
      if (res.code === 401) {
        this.commonService.logout();
        if (isPlatformBrowser(this.platformId)) {
          $("#loginModal").modal("show");
        }
      } else {
        this.responseFromServer =
          res.message || this.translate.instant("popup.went_wrong");
      }
    }
  }

  clearRegistrationState() {
    this.window = "login";
    if (isPlatformBrowser(this.platformId) && localStorage) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    this.email = null;
    this.mobile = null;
    this.responseFromServer = null;
    this.registrationState = false;
    this.password = null;
    this.mobileOrEmail = null;
    this.socialMobileOrEmail = null;
    this.mobile = null;
    this.email = null;
    this.otp = null;
    this.social_login_id = undefined;
    this.commonService.loginModalClose.next(true);
    this.forgotPasswordForResendOTP = false;
  }

  resendOtp() {
    let body: {};
    if (this.$mKey) {
      body = { mKey: this.$mKey };
    } else if (this.$fpKey) {
      body = { fpKey: this.$fpKey };
    }
    if (this.mobile) {
      body = { ...body, mobile: this.mobile };
    } else if (this.email) {
      body = { ...body, email: this.email };
    }
    if (this.registrationState === true) {
      this.registrationSubmit();
      return;
    }
    if (this.forgotPasswordForResendOTP) {
      this.forgotPassword();
    } else {
      this.loginService
        .resdOtp(body)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (res) => {
            if (res.code === 200) {
              this.otpTimer();
            } else {
              if (res.code === 401) {
                this.commonService.logout();
                if (isPlatformBrowser(this.platformId)) {
                  $("#loginModal").modal("show");
                }
              } else {
                this.responseFromServer =
                  res.message || this.translate.instant("popup.went_wrong");
              }
            }
          },
          (err) => {
            this.responseFromServer = this.translate.instant(
              "popup.internal_error"
            );
          },
          () => {}
        );
    }
  }

  registrationSubmit() {
    const requested_identifier = localStorage.getItem("requested_identifier");

    if (
      this.registerInfo.value.email !== requested_identifier &&
      this.registerInfo.value.mobile !== requested_identifier
    ) {
      localStorage.removeItem("requested_identifier");
      localStorage.removeItem("ruid");
    }
    this.registrationState = true;
    this.loginService
      .registration(
        JSON.parse(
          JSON.stringify({
            social_login_id: this.social_login_id,
            ...this.registerInfo.value,
          })
        )
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          if (res.status === "success" || res.code === 200) {
            this.window = "otp";
            this.responseFromServer = undefined;
            this.otpTimer();
          } else {
            if (res.code === 401) {
              this.commonService.logout();
              if (isPlatformBrowser(this.platformId)) {
                $("#loginModal").modal("show");
              }
            } else {
              this.responseFromServer =
                res.message || this.translate.instant("popup.went_wrong");
            }
          }
        },
        (err) => {
          this.responseFromServer = this.translate.instant(
            "popup.internal_error"
          );
        }
      );
  }

  otpSubmit() {
    const requested_identifier = localStorage.getItem("requested_identifier");

    if (
      this.registerInfo &&
      this.registerInfo.value.mobile &&
      this.registerInfo.value.email &&
      this.registerInfo.value.mobile !== requested_identifier &&
      this.registerInfo.value.email !== requested_identifier
    ) {
      localStorage.removeItem("requested_identifier");
      localStorage.removeItem("ruid");
    }
    if (
      this.mobile &&
      this.email &&
      this.mobile !== requested_identifier &&
      this.email !== requested_identifier
    ) {
      localStorage.removeItem("requested_identifier");
      localStorage.removeItem("ruid");
    }
    this.loginService
      .otpSubmit({
        mobile: this.mobile,
        email: this.email,
        otp: this.otp,
        mKey: this.$mKey,
        ...(this.registrationState ? this.registerInfo.value : {}),
        access_token: this.access_token,
        social_login_id: this.social_login_id,
        provider: this.provider,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          if (res.status === "success" || res.code === 200) {
            if (res.ui === "reset-password") {
              this.$fpKey = res.data.fpKey;
              this.$mKey = undefined;
              this.responseFromServer = undefined;
              this.window = res.ui;
            } else {
              // this.loginStatus.loginStatusSet(true);
              // this.responseFromServer = undefined;
              this.loginAction(res);
              // if (isPlatformBrowser(this.platformId)) {
              //   localStorage.setItem('token', res.token);
              //   localStorage.setItem('user', JSON.stringify(res.data));
              // }
              // $('#loginModal').modal('hide');
              // this.window = 'login';
            }
          } else {
            if (res.code === 401) {
              this.commonService.logout();
              if (isPlatformBrowser(this.platformId)) {
                $("#loginModal").modal("show");
              }
            } else {
              this.responseFromServer =
                res.message || this.translate.instant("popup.went_wrong");
            }
          }
        },
        (err) => {
          this.responseFromServer = this.translate.instant(
            "popup.internal_error"
          );
        }
      );
  }

  forgotPassword() {
    this.forgotPasswordForResendOTP = true;
    this.loginService
      .forgotPasswordOTPSend({
        email: this.email,
        mobile: this.mobile,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          if (res.code === 200 || res.status === "success") {
            this.window = "otp";
            this.responseFromServer = undefined;
            this.$mKey = undefined;
            this.otpTimer();
          } else {
            this.responseFromServer =
              res.message || this.translate.instant("popup.went_wrong");
          }
        },
        (err) => {
          this.responseFromServer = this.translate.instant(
            "popup.internal_error"
          );
        },
        () => {}
      );
  }

  forgotPasswordSubmit(password1: string, password2: string) {
    const body = {
      password: password1,
      password_confirmation: password2,
      mobile: this.mobile,
      email: this.email,
      fpKey: this.$fpKey,
    };
    this.loginService
      .forgotPasswordRestore(body)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          if (res.status === "success" || res.code === 200) {
            this.$fpKey = undefined;
            this.responseFromServer = undefined;
            this.password1 = this.password2 = undefined;
            if (isPlatformBrowser(this.platformId) && localStorage) {
              localStorage.setItem("user", JSON.stringify(res.data));
              localStorage.setItem("token", res.token);
            }
            this.loginStatus.loginStatusSet(true);
            this.responseFromServer = undefined;
            this.window = "login";
            setTimeout(() => {
              if (isPlatformBrowser(this.platformId)) {
                $("#loginModal").modal("hide");
              }
            }, 100);
            window.location.reload();
          } else {
            this.loginStatus.loginStatusSet(false);
            if (res.code === 401) {
              this.commonService.logout();
              if (isPlatformBrowser(this.platformId)) {
                $("#loginModal").modal("show");
              }
            } else {
              this.responseFromServer =
                res.message || this.translate.instant("popup.went_wrong");
            }
          }
        },
        (err) => {
          this.responseFromServer = this.translate.instant(
            "popup.internal_error"
          );
        }
      );
  }

  mobileNumberStore() {
    this.loginService
      .mobileNumberUpdateForOldUser({
        mobile: this.mobile,
        email: this.email,
        mKey: this.$mKey,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          if (res.status === "success" || res.code === 200) {
            this.window = "otp";
            this.otpTimer();
            this.responseFromServer = undefined;
          } else {
            this.responseFromServer =
              res.message || this.translate.instant("popup.went_wrong");
          }
        },
        (err) => {
          this.responseFromServer =
            this.translate.instant("popup.internal_error") ||
            this.translate.instant("popup.went_wrong");
        }
      );
  }

  private otpTimer() {
    this.timer = 120;
    const otpTimer = setInterval(() => {
      this.timer = this.timer - 1;
      if (this.timer <= 0) {
        clearInterval(otpTimer);
        this.timer = 0;
      }
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  navigate(s: string) {
    if (isPlatformBrowser(this.platformId)) {
      $("#loginModal").modal("hide");
    }
    this.router.navigateByUrl(s);
  }

  facebookSignIn() {
    try {
      this.isSocialLogin = true;
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    } catch (e) {
      // console.log(e)
    }
  }

  googleSignIn() {
    try {
      this.isSocialLogin = true;
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    } catch (e) {}
  }

  socialLogin(email) {
    this.loginService
      .socialLogin({ email })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          this.loginAction(res, true);
        },
        (err) => {
          this.responseFromServer = this.translate.instant(
            "popup.internal_error"
          );
        }
      );
  }

  getTimerInMinutes(n: number) {
    if (n === 0) {
      return Math.floor(this.timer / 60);
    } else {
      return Math.floor(this.timer % 60);
    }
  }
}
