import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../services/common.service';
import {takeUntil} from 'rxjs/operators';
import {isPlatformBrowser} from "@angular/common";

declare var Swiper: any;

@Component({
  selector: 'app-exclusive-offers',
  templateUrl: './exclusive-offers.component.html',
  styleUrls: ['./exclusive-offers.component.scss']
})
export class ExclusiveOffersComponent implements OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  offers: any = [];
  oSwipper: any;

  constructor(private commonService: CommonService, public translate: TranslateService, @Inject(PLATFORM_ID) private platformId: Object) {
    //
  }

  ngOnInit() {
    // this.documentReady();
    this.getAllExclusiveOffers();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  documentReady(wrap, next, prev) {
    if (isPlatformBrowser(this.platformId)) {
      this.oSwipper = new Swiper(wrap, {
        slidesPerView: 'auto',
        // freeMode: true,
        spaceBetween: 0,
        speed: 1500,
        loop: false,
        autoplay: false,
        observer: true,
        observeParents: true,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false,
        // },

        // observer: true,
        // observeParents: true,
        // parallax: true,

        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        breakpoints: {
          640: {
            freeMode: false,
            slidesPerView: 2,
            spaceBetween: 0,
          },
          320: {
            freeMode: false,
            slidesPerView: 1,
            spaceBetween: 0,
          }
        }
      });
    }
  };

  getAllExclusiveOffers() {
    this.commonService.getExclusiveOffers().pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (res.code === 200 || res.status === 'success') {
        this.offers = res.data;
      }
    });
  }

  initCarousel(offerTitleSlug, index) {
    let wrap = '#' + offerTitleSlug + '-child-' + index;
    let next = wrap + '-next';
    let prev = wrap + '-prev';
    this.documentReady(wrap, next, prev);
  }
}
