import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appEmailOrMobile]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailOrMobileDirective, multi: true}]
})
export class EmailOrMobileDirective implements Validator {

  constructor() {
  }

  registerOnValidatorChange(fn: () => void): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return undefined;
  }

}
