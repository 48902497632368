import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { User } from "../model/user.model";
import { environment } from "../../environments/environment";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private token: string;
  private device_key: string;
  public $User = new BehaviorSubject<User>(new User({}));

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.$User.subscribe((res) => {
      if (!res) {
        this.getUserProfile({});
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem("user"));
      this.$User.next(new User(user));
    }
  }

  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem("token");
      this.device_key = localStorage.getItem("device_key");
    }
  }

  profileUpdate(body) {
    this.getToken();
    return this.http.post<any>(environment.easy_core + "profile-update", {
      ...body,
      token: this.token,
      device_key: this.device_key,
    });
  }

  passwordUpdate(body) {
    this.getToken();
    return this.http.post<any>(environment.easy_core + "password-update", {
      ...body,
      token: this.token,
      device_key: this.device_key,
    });
  }

  getDevices(body) {
    this.getToken();
    return this.http.post<any>(
      environment.easy_core + "get-logged-in-devices",
      {
        ...body,
        token: this.token,
        device_key: this.device_key,
      }
    );
  }

  logoutByDevicekey(body) {
    this.getToken();
    return this.http.post<any>(environment.easy_core + "logout", {
      ...body,
      token: this.token,
      session_key: this.device_key,
    });
  }

  logoutFromAlldevices(body) {
    this.getToken();
    return this.http.post<any>(
      environment.easy_core + "logout-from-all-devices",
      {
        ...body,
        token: this.token,
        device_key: this.device_key,
      }
    );
  }

  getUserProfile(body) {
    this.getToken();
    this.http
      .post<any>(environment.easy_core + "get-user-profile", {
        ...body,
        token: this.token,
        device_key: this.device_key,
      })
      .subscribe((res) => {
        if (res.code === 200 || res.status.toLowerCase() === "success") {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem("user", res.data);
            this.$User.next(new User(res.data));
          }
        }
      });
  }

  updateProfileImage(file) {
    this.getToken();
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'multipart/form-data',
    //   })
    // };

    let formData: FormData = new FormData();
    formData.append("token", this.token);
    formData.append("device_key", this.device_key);
    formData.append("profile_picture", file, file.name);

    return this.http.post<any>(
      environment.easy_core + "profile-picture-upload",
      formData
    );
  }
}
