import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RechargeComponent} from './recharge.component';
import {TopUpComponent} from './top-up/top-up.component';

const routes: Routes = [
  {path: '', redirectTo: 'top-up', pathMatch: 'full'},
  {
    path: '', component: RechargeComponent,
    children: [
      {path: 'top-up', component: TopUpComponent},
      {path: ':ruid', component: TopUpComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RechargeRoutingModule {
}
