import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {LoginStatusService} from '../services/login-status.service';
import {LoginService} from '../services/login.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private router: Router, private loginStatusService: LoginStatusService, private loginService: LoginService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.loginStatus();
  }


  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

     return true;

  }

  loginStatus() {
    let response = true;
    const token = localStorage.getItem('token');
    if (token) {
      this.loginService.loginStatus(token).subscribe(
        res => {
          if (res.code === 200 || res.status === 'success') {
            response = true;
          } else {
            this.router.navigate(['recharge']).then(r => {
              $('#loginModal').modal('show');
            });
          }
        }
      );
    } else {
      this.router.navigate(['recharge']).then(r => {
        $('#loginModal').modal('show');
      });
    }
    return response;
  }
}
