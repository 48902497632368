import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root", // This makes the service available globally across the application
})
export class GlobalService {
  // Define your global variables
  public loginStatus: boolean = false;
  public someOtherGlobalValue: string = "";

  constructor() {}

  // Optionally, add getter/setter methods if you need more control
  setLoginStatus(status: boolean) {
    this.loginStatus = status;
  }

  getLoginStatus(): boolean {
    return this.loginStatus;
  }
}
