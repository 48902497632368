import {library} from '@fortawesome/fontawesome-svg-core';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import {faGooglePlusG} from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import {faPinterestP} from '@fortawesome/free-brands-svg-icons/faPinterestP';
import {faRedditAlien} from '@fortawesome/free-brands-svg-icons/faRedditAlien';
import {faTumblr} from '@fortawesome/free-brands-svg-icons/faTumblr';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import {faVk} from '@fortawesome/free-brands-svg-icons/faVk';
import {faFacebookMessenger} from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import {faTelegramPlane} from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import {faMix} from '@fortawesome/free-brands-svg-icons/faMix';
import {faXing} from '@fortawesome/free-brands-svg-icons/faXing';
import {faCommentAlt} from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import {faBook} from '@fortawesome/free-solid-svg-icons/faBook';
import {faLine} from '@fortawesome/free-brands-svg-icons/faLine';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faPrint} from '@fortawesome/free-solid-svg-icons/faPrint';
import {faExclamation} from '@fortawesome/free-solid-svg-icons/faExclamation';
import {faLink} from '@fortawesome/free-solid-svg-icons/faLink';
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';
import {faLightbulb} from '@fortawesome/free-solid-svg-icons/faLightbulb';
import {faCoffee} from '@fortawesome/free-solid-svg-icons/faCoffee';
import {faInfo} from '@fortawesome/free-solid-svg-icons/faInfo';


const icons = [
  faFacebookF, faTwitter, faLinkedinIn, faGooglePlusG, faPinterestP, faRedditAlien, faTumblr,
  faWhatsapp, faVk, faFacebookMessenger, faTelegramPlane, faMix, faXing, faCommentAlt, faBook, faLine,
  faEnvelope, faCheck, faPrint, faExclamation, faLink, faEllipsisH, faMinus, faLightbulb, faCoffee, faInfo
];

library.add(...icons);

// facebook','twitter','linkedin','telegram','messenger','whatsapp'
