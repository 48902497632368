import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RechargeRequestModalService {
  constructor(private httpClient: HttpClient) {}

  requestedBehavior: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  rechargeRequestIdentifier: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  getUserData() {
    if (localStorage) {
      const user = localStorage.getItem("user");
      return JSON.parse(user);
    }
  }

  rechargeRequest(body = {}) {
    return this.httpClient.post<any>(
      environment.easy_core + "recharge-request/create",
      body
    );
  }

  rechargeRequestList(body = {}) {
    return this.httpClient.post<any>(
      environment.easy_core + "recharge-request/list",
      body
    );
  }

  rechargeRequestedList(body = {}) {
    return this.httpClient.post<any>(
      environment.easy_core + "recharge-request/requested-list",
      body
    );
  }

  rejectRechargeRequest(body = {}) {
    return this.httpClient.post<any>(
      environment.easy_core + "recharge-request/reject",
      body
    );
  }

  getOperator(operator_id) {
    switch (+operator_id) {
      case 1:
        return "Grameenphone";
      case 2:
        return "Banglalink";
      case 3:
        return "Robi";
      case 4:
        return "";
      case 5:
        return "Teletalk";
      case 6:
        return "Airtel";
    }
  }

  rechargeRequestInfo(ruid: string) {
    return this.httpClient.post<any>(
      environment.easy_core + "recharge-request/info",
      { ruid: ruid }
    );
  }

  setRequestBody(requested: any) {
    this.requestedBehavior.next(requested);
  }

  getRequestBody() {
    return this.requestedBehavior;
  }

  getMobileOrEmailForRechargeRequest() {
    return this.rechargeRequestIdentifier;
  }

  setMobileOrEmailForRechargeRequest(rechargeRequestIdentifier: string) {
    return this.rechargeRequestIdentifier.next(rechargeRequestIdentifier);
  }
}
