import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RechargeRoutingModule} from './recharge-routing.module';
import {RechargeComponent} from './recharge.component';
import {TopUpComponent} from './top-up/top-up.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Ng2PageScrollModule} from 'ng2-page-scroll';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {LangBaseTranslateModule} from '../translate/lang-base-translate/lang-base-translate.module';
import {RechargeRequestModalComponent} from './recharge-request-modal/recharge-request-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {RechargeRequestModalService} from "./recharge-request-modal/recharge-request-modal-service";

@NgModule({
  declarations: [
    RechargeComponent,
    TopUpComponent,
    RechargeRequestModalComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    RechargeRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2PageScrollModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    LangBaseTranslateModule,
    FormsModule,
    MatDialogModule,
  ],
  providers: [],
  entryComponents: [RechargeRequestModalComponent]
})
export class RechargeModule {
}
