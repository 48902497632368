import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { LoginStatusService } from "../../services/login-status.service";
import { LoginService } from "../../services/login.service";
import { CommonService } from "../../services/common.service";
import { Router } from "@angular/router";
import {
  debounceTime,
  delay,
  take,
  takeUntil,
  map,
  distinctUntilChanged,
} from "rxjs/operators";
import { fromEvent, Observable, Subject, merge } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../services/user.service";
import { lang } from "moment";
import { User } from "../../model/user.model";
import { isPlatformBrowser } from "@angular/common";

declare var $: any;

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit, AfterViewInit, OnDestroy {
  // tslint:disable-next-line:variable-name
  public login_status = true;
  notifications: {
    title: string;
    message: string;
    link: string;
    icon_url: string;
    status: number;
    notified_at: string;
  }[] = [];
  showAll = false;
  currentLang = "en";

  public user: {
    name: string;
    email: string;
    mobile: string;
    profilePictureUrl: string;
  };

  @ViewChild("searchField", { static: false }) searchField: ElementRef;

  public searchKey = "";
  public searches: any = [];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private loginStatus: LoginStatusService,
    private loginService: LoginService,
    private commonService: CommonService,
    private router: Router,
    public translate: TranslateService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.loginStatus
      .loginStatusGet()
      .pipe(takeUntil(this.onDestroy$), delay(100))
      .subscribe((res) => {
        this.login_status = !res;
        if (res) {
          if (isPlatformBrowser(this.platformId) && localStorage) {
            this.user = new User(JSON.parse(localStorage.getItem("user")));
          }
          this.commonService
            .httpPost("user-activity-notification", {})
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (res) => {
                if (res.code === 200 || res.status === "success") {
                  this.notifications = res.data;
                }
              },
              (error) => {}
            );
        }
      });
  }

  ngOnInit() {
    this.assignUser();
    this.commonService.currentLanguage
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((lang) => (this.currentLang = lang));
  }

  assignUser() {
    this.userService.$User
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        if (user) {
          this.user = new User(user);
          if (
            !this.user.profilePictureUrl &&
            isPlatformBrowser(this.platformId)
          ) {
            this.user = new User(JSON.parse(localStorage.getItem("user")));
          }
        }
      });
  }

  logout() {
    let token = "";
    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem("token");
    }
    this.loginService
      .logout(token)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (res) => {
          if (
            (res.code === 200 ||
              res.code === 401 ||
              res.status === "success") &&
            isPlatformBrowser(this.platformId) &&
            localStorage
          ) {
            const device_key = localStorage.getItem("device_key");
            const activeLang =
              localStorage.getItem("lang") === null ||
              !localStorage.getItem("lang").length
                ? "en"
                : localStorage.getItem("lang");
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.setItem("lang", activeLang);
            localStorage.setItem("device_key", device_key);
            this.loginStatus.loginStatusSet(false);
            this.commonService.successPopup(
              this.translate.instant("popup.success") + "!",
              res.message || this.translate.instant("popup.logout"),
              () => {
                window.location.reload();
              }
            );
            this.router.navigate(["recharge", "top-up"]);
          } else {
            this.commonService.errorPopup(
              this.translate.instant("popup.fail") + "!",
              res.message || this.translate.instant("popup.went_wrong")
            );
          }
        },
        (err) => {
          this.commonService.errorPopup(
            this.translate.instant("popup.error") + "!",
            this.translate.instant("popup.internal_error")
          );
        },
        () => {}
      );
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      $("#mainNav").affix({
        offset: {
          top: 100,
        },
      });
    }

    const events = ["keyup", "keydown"];
    const eventStreams = events.map((ev) =>
      fromEvent(this.searchField.nativeElement, ev)
    );
    const allEvents$ = merge(...eventStreams);

    allEvents$
      .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
      .pipe(debounceTime(1000))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (value.length) {
          this.getSearches(value);
        } else {
          this.searches = [];
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  changeLanguage(lang) {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  notificationClear() {
    this.commonService
      .httpPost("clear-user-activity-notification", {})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.code === 200 || res.status === "success") {
          this.notifications = [];
          this.commonService.successPopup(
            this.translate.instant("popup.success") + "!",
            this.translate.instant("popup.notification_delete")
          );
        } else {
          this.commonService.successPopup(
            this.translate.instant("popup.fail") + "!",
            this.translate.instant("popup.unable_notification_delete")
          );
        }
      });
  }

  getNotification() {
    if (this.showAll) {
      return this.notifications;
    } else {
      const notification = this.notifications.filter(
        (notification) => notification.status == 0
      );
      if (notification.length == 0) {
        this.showAll = false;
        return this.notifications;
      }
      return notification;
    }
  }

  getNotificationUnread() {
    try {
      return this.notifications.filter(
        (notification) => notification.status == 0
      ).length;
    } catch (e) {
      return 0;
    }
  }

  readNotification() {
    const notification = this.notifications.filter(
      (notification) => notification.status == 0
    );
    if (notification.length > 0) {
      this.commonService
        .httpPost("read-user-activity-notification", {})
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          if (res.code === 200 || res.status === "success") {
            this.notifications = this.notifications.map((notification) => {
              notification.status = 1;
              return notification;
            });
          }
        });
    }
  }

  loginEventFire() {
    this.commonService.setLoginEvent(true);
  }

  getSearches(key) {
    this.commonService
      .getSearches({ key })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.code === 200 || res.status === "success") {
          this.searches = res.data;
        }
      });
  }

  goToSearchItem() {
    if (this.searches.length) {
      const item = this.searches[0];
      window.location.href = item.url;
    }
  }

  getFormattedDate(notified_at) {
    return new Date(notified_at).toLocaleString();
  }
}
