import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  constructor(private _location: Location) {
  }

  ngOnInit() {
    document.querySelector('body').classList.add('error-404');
  }

  goback() {
    window.location.href = '/recharge/top-up';
  }
}
