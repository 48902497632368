import {Component, OnDestroy, OnInit} from '@angular/core';
import {TopupService} from '../../../services/recharge/topup.service';
import {CommonService} from '../../../services/common.service';
import {Topupoffer} from '../../../model/topupoffer.interface';
import {of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LoadingBarService} from '@ngx-loading-bar/core';


@Component({
  selector: 'app-recharge-offer',
  templateUrl: './recharge-offer.component.html',
  styleUrls: ['./recharge-offer.component.scss']
})
export class RechargeOfferComponent implements OnInit, OnDestroy {
  public offerDataVoice: Topupoffer[] = [];
  public offerDataSMS: Topupoffer[] = [];
  public offerDataInternet: Topupoffer[] = [];
  public offerDataCombo: Topupoffer[] = [];
  public pageForVoice = 1;
  public pageForInternet = 1;
  public pageForSMS = 1;
  public pageForCombo = 1;
  public tabForVoice = true;
  public tabForInternet = false;
  public tabForSMS = false;
  public tabForCombo = false;
  private onDestroy$: Subject<void> = new Subject<void>();
  public isLoadingEnable = true;
  currentLang = 'en';

  constructor(
    private topupService: TopupService, private readonly commomService: CommonService, private lo: LoadingBarService) {
  }

  ngOnInit() {
    this.commomService.currentLanguage.pipe(takeUntil(this.onDestroy$)).subscribe(el => {
      this.currentLang = el;
    });
    this.lo.progress$.pipe(takeUntil(this.onDestroy$)).subscribe(re => {
      if (re === 100) {
        this.isLoadingEnable = false;
      }
    });
    this.topupService.offerRecharge.pipe(takeUntil(this.onDestroy$)).subscribe(
      res => {
        if (res) {
          this.offerDataCombo = [];
          this.offerDataInternet = [];
          this.offerDataSMS = [];
          this.offerDataVoice = [];
          this.pageForVoice = 1;
          this.pageForInternet = 1;
          this.pageForSMS = 1;
          this.pageForCombo = 1;
          for (const offer of res) {
            if (offer.offer_type === 'voice') {
              this.offerDataVoice.push(offer);
            } else if (offer.offer_type === 'internet') {
              this.offerDataInternet.push(offer);
            } else if (offer.offer_type === 'sms') {
              this.offerDataSMS.push(offer);
            } else {
              this.offerDataCombo.push(offer);
            }
          }
        }
      }
    );
  }

  setOfferRechargeDetails(data) {
    this.topupService.offerRechargeSelect.next(data);
  }

  voiceTab() {
    this.tabForVoice = true;
    this.tabForInternet = false;
    this.tabForSMS = false;
    this.tabForCombo = false;
  }

  internetTab() {
    this.tabForVoice = false;
    this.tabForInternet = true;
    this.tabForSMS = false;
    this.tabForCombo = false;
  }

  smsTab() {
    this.tabForVoice = false;
    this.tabForInternet = false;
    this.tabForSMS = true;
    this.tabForCombo = false;
  }

  comboTab() {
    this.tabForVoice = false;
    this.tabForInternet = false;
    this.tabForSMS = false;
    this.tabForCombo = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}
