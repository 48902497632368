import {AfterContentInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {LoginService} from './services/login.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from './services/common.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {MessagingService} from './services/messaging.service';
import {LoginStatusService} from './services/login-status.service';
import {isPlatformBrowser} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentInit, OnDestroy, OnInit {
  title = 'easy';
  message;
  isLoggedIn = false;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private login: LoginService,
    private lo: LoadingBarService,
    private commonService: CommonService,
    public translate: TranslateService,
    private router: Router,
    private messagingService: MessagingService,
    private loginStatusService: LoginStatusService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.translateInit();

    lo.progress$.pipe(takeUntil(this.onDestroy$)).subscribe(re => {
    });
    // console.log(this.login.signInWithGoogle());
    /*    this.login.signInWithFB().then(
          res => {

          },
          err => {

          }
        );*/
    // this.login.signInWithFB().then(
    //   res => {
    //
    //   },
    //   err => {
    //
    //   }
    // );
    /*    this.login.signInWithGoogle().then(
          res => {
            console.log('start google');
            console.log(res);
            console.log('end google');
          },
          err => {
            console.log('start google e');
            console.log(err);
            console.log('end google e');
          }
        );*/
  }

  translateInit(defaultLang = 'en') {
    if (isPlatformBrowser(this.platformId) && localStorage) {
      this.translate.addLangs(['en', 'bn']);
      this.translate.setDefaultLang(defaultLang);
      const browserLang = this.translate.getBrowserLang();
      this.translate.use(browserLang.match(/en|bn/) ? browserLang : defaultLang);
    }
  }

  ngAfterContentInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#loading').fadeOut('slow');
    }
    this.commonService.getServiceList();
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
    // this.btnAct.unsubscribe();
    // this.wowSubscription.unsubscribe();
    // localStorage.setItem('p-m-flag', '1'); //promotional modal logic
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();

  }

  ngOnInit(): void {
    this.loginStatusService.loginStatusGet().pipe(takeUntil(this.onDestroy$)).subscribe(res => this.isLoggedIn = res);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.messagingService.currentMessage.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (this.isLoggedIn && res && res.notification && res.notification.title && (res.notification.title || '').toLowerCase() === 'logout') {
        this.commonService.logout();
      }
    });
    this.commonService.currentLanguage.pipe(takeUntil(this.onDestroy$)).subscribe(currentLanguage => {
      if (currentLanguage === 'en') {
        document.querySelector('body').classList.add('en');
        document.querySelector('body').classList.remove('bn');
      } else {
        document.querySelector('body').classList.add('bn');
        document.querySelector('body').classList.remove('en');
      }
    });
  }
}
