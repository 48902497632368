import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-success-popup',
  templateUrl: './promotional-modal.component.html',
  styleUrls: ['./promotional-modal.component.scss']
})
export class PromotionalModalComponent implements OnInit {
  public head: any;
  public message: any;
  public url: any;
  okayCallbackFunction: Function;

  constructor(public dialogRef: MatDialogRef<PromotionalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) { 
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
    if (this.okayCallbackFunction instanceof Function) {
      this.okayCallbackFunction();
    }
  }
}
