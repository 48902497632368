import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatorPipe } from '../pipes/translator.pipe';



@NgModule({
  declarations: [TranslatorPipe],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
