import { Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from '../../services/common.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss']
})
export class RechargeComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  public menuItemWidth: number;

  constructor(private commonService: CommonService) {
  }

  ngOnInit() {

    this.commonService.menuItemWidth.pipe(takeUntil(this.onDestroy$)).subscribe(res => this.menuItemWidth = res);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

}
/*


private changeDetectorRef: ChangeDetectorRef,
  this.changeDetectorRef.detach();*/
