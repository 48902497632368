import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import easyCheckout from "easycheckout";

@Injectable({
  providedIn: "root",
})
export class ECPopupService {
  iframe;
  modal;
  isMobile = false;

  constructor(
    private mobileDetect: DeviceDetectorService,
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = deviceService.isMobile();
  }

  topupinit(body: any) {
    const device_key = localStorage.getItem("device_key");
    const token = localStorage.getItem("token");
    return this.http.post<any>(environment.easy_core + "vr/top-up", {
      ...body,
      device_key,
      token,
    });
  }

  movieTicketBook(body: any, path) {
    const device_key = localStorage.getItem("device_key");
    const token = localStorage.getItem("token");
    return this.http.post<any>(environment.easy_core + path, {
      ...body,
      device_key,
      token,
    });
  }

  ivacinit(body: any) {
    const device_key = localStorage.getItem("device_key");
    const token = localStorage.getItem("token");
    return this.http.post<any>(environment.easy_core + "ivac/bill-payment", {
      ...body,
      device_key,
      token,
    });
  }

  topupCreate(body: any) {
    const device_key = localStorage.getItem("device_key");
    const token = localStorage.getItem("token");
    return this.http.post<any>(environment.easy_core + "top-up", {
      ...body,
      device_key,
      token,
    });
  }

  billPay(body: any, service) {
    const device_key = localStorage.getItem("device_key");
    const token = localStorage.getItem("token");
    return this.http.post<any>(
      environment.easy_core + service + "/bill-payment",
      { ...body, device_key, token }
    );
  }

  paymentStatus(tran_id: string) {
    const token = localStorage.getItem("token");
    const device_key = localStorage.getItem("device_key");
    return this.http.post<any>(environment.easy_core + "payment-status", {
      tran_id,
      token,
      device_key,
    });
  }

  loadModal(body: any) {
    easyCheckout(
      body.url,
      body.logo,
      true,
      this.isMobile || window.screen.height < 800 || window.screen.width < 400
    );
  }

  subscriptionPay(body: any) {
    const device_key = localStorage.getItem("device_key");
    const token = localStorage.getItem("token");
    return this.http.post<any>(
      environment.easy_core +
        "subscription/rabbit-hole/booking-subscription-coupon-code",
      { ...body, device_key, token }
    );
  }

  trueCallerBillInfo(body: any) {
    const device_key = localStorage.getItem("device_key");
    const token = localStorage.getItem("token");
    return this.http.post<any>(environment.easy_core + "dynamic/bill-info", {
      ...body,
      device_key,
      token,
    });
  }

  trueCallerBillPayment(body: any) {
    const device_key = localStorage.getItem("device_key");
    const token = localStorage.getItem("token");
    return this.http.post<any>(environment.easy_core + "dynamic/bill-payment", {
      ...body,
      device_key,
      token,
    });
  }
}
