import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    $('.index').addClass('inner');
    $('.inner').removeClass('index');
  }

}
