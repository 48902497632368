import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoginStatusService {

  private loginObservable = new BehaviorSubject(false);

  // tslint:disable-next-line:ban-types
  constructor(private  http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    const token = isPlatformBrowser(this.platformId) ? localStorage.getItem('token') : '';
    if (token && token.length !== 0) {
      this.loginObservable.next(true);
    } else {
      this.loginObservable.next(false);
    }
  }

  loginStatusSet(value) {
    this.loginObservable.next(value);
  }

  loginStatusGet() {
    return this.loginObservable;
  }

}
