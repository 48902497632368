export class User {
  public name: string;
  public email: string;
  public profilePictureUrl: string;
  public mobile: string;
  public gender: string;
  public dateOfBirth: string;
  public occupation: string;
  public organization: string;
  public memberSince: string;
  public completedPercentage = 100;
  public address: string;
  public emailVerified: boolean | string | number;
  public mobileVerified: boolean;

  // public address: string;

  constructor(user) {
    this.name = user ? user.name || 'N/A' : 'N/A';
    this.email = user ? user.email || 'N/A' : 'N/A';
    this.mobile = user ? user.mobile || 'N/A' : 'N/A';
    this.gender = user ? user.gender || 'N/A' : 'N/A';
    this.dateOfBirth = user ? user.date_of_birth || 'N/A' : 'N/A';
    this.occupation = user ? user.occupation || 'N/A' : 'N/A';
    this.organization = user ? user.organization || 'N/A' : 'N/A';
    this.memberSince = user ? user.member_since || 'N/A' : 'N/A';
    this.profilePictureUrl = user ? user.profile_picture_url || null : null;
    this.address = user ? user.address || 'N/A' : 'N/A';
    // tslint:disable-next-line:triple-equals
    this.emailVerified = user ? user.email_verified : 'N/A';
    this.mobileVerified = user ? user.mobile_verified : 'N/A';
    if (user) {
      this.completedPercentageCount(user);
    }
  }

  private completedPercentageCount(user) {
    if (!user.name) {
      this.completedPercentage -= 20;
    }
    if (!user.email) {
      this.completedPercentage -= 20;
    }
    if (!user.mobile) {
      this.completedPercentage -= 25;
    }
    if (!user.gender) {
      this.completedPercentage -= 10;
    }
    if (!user.date_of_birth) {
      this.completedPercentage -= 5;
    }
    if (!user.occupation) {
      this.completedPercentage -= 5;
    }
    if (!user.organization) {
      this.completedPercentage -= 5;
    }
    if (!user.member_since) {
      this.completedPercentage -= 5;
    }
    if (!user.address) {
      this.completedPercentage -= 5;
    }
    this.completedPercentage = Math.round(this.completedPercentage);
  }
}
