import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit {

  public head: any;
  public message: any;
  buttonName: string;
  Oops: boolean;

  constructor(public dialogRef: MatDialogRef<ErrorPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {}) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
